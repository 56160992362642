
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
import { defineComponent } from 'vue';
import { Storage } from '@ionic/storage';
import { trashOutline } from 'ionicons/icons';
//import { useRoute } from 'vue-router';

const store = new Storage({name: 'dicstorage'});
//let resultDB2 = ['TEST'];

// https://lukashermann.dev/writing/how-to-use-async-await-with-vuejs-components/
// https://learnvue.co/2020/12/how-to-use-lifecycle-hooks-in-vue3/
export default defineComponent({
  name: 'Tab2',
  components: { IonHeader, IonToolbar, IonTitle,
		IonContent, IonPage },
    setup() {
	console.log("setup");
	/*onMounted(async function () {
            await store.create();
            this.resultDB = await store.keys();
	    });*/
	//const route = useRoute();
	//console.log("route", route.name);
	return { trashOutline }
    },
    async created() {
	await store.create();	
	//this.resultDB = await store.keys();
	const gespeichert = await store.get('gespeichert');
	this.resultDB = gespeichert ? JSON.parse('['+gespeichert+']') : [''];
	console.log("created", this.resultDB);	
   },
   data() {
       return {
           resultDB: [''],
       }
    },
   watch: {
       resultDB: function()  {
           setTimeout(async () => {
               //this.resultDB = await store.keys();
	       const gespeichert = await store.get('gespeichert');
	       this.resultDB = gespeichert ? JSON.parse('['+gespeichert+']') : [''];
	       //console.log("result", gespeichert);
             console.log("timeout");
           }, 2000); //https://stackoverflow.com/a/48014125
       },
   },
  methods: {
      async mydel(key1: string, key2: string) {
	  const gespeichert = await store.get('gespeichert');
	  const res = JSON.parse('['+gespeichert+']');
	  const valdel = res.filter((item: any) => {
		console.log("key1",  item.DStichwort.match('^'+key1+'$'));
		return item.DStichwort.match('^'+key1+'$') &&
		item.RStichwort.match('^'+key2+'$');
	  })
	    //await store.remove(key);
          console.log("mydel", valdel);
	    //this.resultDB = await store.keys();
        },
},
 

})
